import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { basicAuth, firebase, i18n } from "shared";
// import "../utils/css-imports";
// import "antd/dist/antd.css";
import { ConfigProvider, Layout, notification } from "antd";
import enUS from "antd/lib/locale/en_US";
import jaJP from "antd/lib/locale/ja_JP";
import { appWithTranslation, useTranslation } from "next-i18next";
import App, { AppProps } from "next/app";
import Head from "next/head";
import { QueryClient, QueryClientProvider } from "react-query";
import styled from "styled-components";
import { Sidebar } from "../components/molecules/Sidebar";
import { AuthProvider } from "../utils/AuthContext";
import "../utils/globalStyles.css";

const LayoutWrapper = styled.div`
  width: 100%;
  overflow-x: auto;
  overflow-y: scroll;
  height: 100vh;
  background-color: #f0f2f5;
  & .ant-layout-header {
    height: 54px;
    padding: 0 50px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 64px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }
  & .ant-layout-content {
    padding: 30px;
    height: 100%;
  }
  & .ant-layout-sider-children {
    background: #ffffff;
  }
`;

const queryClient = new QueryClient();
const MyApp = ({ Component, pageProps }: AppProps) => {
  const routers = useRouter();
  const [user, setUser] = useState(null as firebase.User | null);
  const { t } = useTranslation();

  const initialLoad = () => {
    firebase.auth().onAuthStateChanged(async (user) => {
      try {
        if (user) {
          setUser(user);
        }
      } catch (error) {
        notification.error({ type: "error", message: t("Error occured") });
      }
    });
  };
  useEffect(() => {
    initialLoad();
  }, []);
  const restrictedRoute = ["/login"];

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Head>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
          />
          <title>Admin | Kansai Startup Ecosystem</title>
        </Head>

        <ConfigProvider
          locale={
            i18n.language === "en-US" || i18n.language === "en" ? enUS : jaJP
          }
        >
          <AuthProvider>
            <Layout>
              {user && !restrictedRoute.includes(routers.pathname) && (
                <Sidebar />
              )}
              <LayoutWrapper>
                <Component {...pageProps} />
              </LayoutWrapper>
            </Layout>
          </AuthProvider>
        </ConfigProvider>
      </QueryClientProvider>
    </>
  );
};

MyApp.getInitialProps = async (appContext) => {
  const { req, res } = appContext.ctx;
  const appProps = await App.getInitialProps(appContext);
  if (process.env.NEXT_PUBLIC_ENVIRONMENT === "development") {
    basicAuth(req, res);
  }
  return { ...appProps };
};

export default appWithTranslation(MyApp);
